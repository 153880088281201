import React, { Fragment } from "react";
import Header from "../components/Header/Header";
import HeroSection from "../components/Hero-Section/HeroSection";
import AboutUs from "../components/About-us/AboutUs";
import Courses from "../components/Courses-section/Courses";
import ChooseUs from "../components/Choose-us/ChooseUs";
import Features from "../components/Feature-section/Features";
import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../components/Newsletter/Newsletter";
import Footer from "../components/Footer/Footer";
import Pricing from "../components/pricing/Pricing";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <HeroSection />
      {/* <CompanySection /> */}
      <AboutUs />
      <Courses />
      <Pricing />
      <ChooseUs />
      <Features />
      {/* <FreeCourse /> */}
      <Testimonials />
      <Newsletter />
      <Footer />
    </Fragment>
  );
};

export default Home;
