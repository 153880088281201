import React from "react";
import { AiFillCheckSquare } from "react-icons/ai";

function Pricing() {
  return (
    <div>
      <p className="text-center text-2xl font-bold">Membership Plans</p>
      <p className="text-center">
        Note: please enter your correct email during payment. we will use you
        provided email and phone number to reach out.
      </p>

      <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 p-4">
        <div className="border rounded-lg shadow-lg p-6 w-full md:w-1/2 bg-white dark:bg-zinc-800">
          <div className="text-center mb-4">
            <h2 className="text-xl font-semibold">4 hours per month</h2>
            <p className="text-zinc-600">Month-to-month Membership</p>
            <p className="text-lg font-bold">$329 per month</p>
          </div>
          <a
            href="https://buy.stripe.com/test_fZe00O38e1QG3mg5kk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-blue-600 text-white py-2 px-4 rounded-lg w-full">
              Buy Now
            </button>
          </a>

          <div className="mt-6">
            <p className="font-semibold">Membership benefits include:</p>
            <ul className="list-disc list-inside space-y-2 mt-2">
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />4 tutoring hours per month
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                Unlimited live classes
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                On-demand homework help
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                Exclusive access to Codeverse
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                Access for your whole family
              </li>
            </ul>
          </div>
        </div>

        <div className="border rounded-lg shadow-lg p-6 w-full md:w-1/2 bg-gray-50">
          <div className="text-center mb-4">
            <h2 className="text-xl font-semibold">8 hours per month</h2>
            <p className="text-gray-900">Month-to-month Membership</p>
            <p className="text-lg font-bold">$579 per month</p>
          </div>
          <a
            href="https://buy.stripe.com/test_fZe00O38e1QG3mg5kk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-blue-600 text-white py-2 px-4 rounded-lg w-full">
              Buy Now
            </button>
          </a>
          <div className="mt-6">
            <p className="font-semibold">Membership benefits include:</p>
            <ul className="list-disc list-inside space-y-2 mt-2">
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />8 tutoring hours per month
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                Unlimited live classes
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                On-demand homework help
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                Exclusive access to Codeverse
              </li>
              <li className="flex items-center">
                <AiFillCheckSquare className="mr-2" />
                Access for your whole family
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
